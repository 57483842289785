import React from "react";
import Layout from "../layout/layout.component";
import SEO from "../partials/seo/seo.component";
import Container from "react-bootstrap/Container";
import ROUTES from "../configs/route_final";
import PropTypes from "prop-types";

const NotFoundPage = ({t, pageContext}) => {
    const {schema} = pageContext;
    return (
        <Layout>
            {schema ? (<SEO data={schema}/>) : null}
            <Container className="text-center not-found-wrapper">
                <object className="d-lg-none not-found-image" type="image/svg+xml" data="https://images.emit.reviews/404-mobile.svg" aria-label="not-found" />
                <object className="d-none d-lg-block not-found-image" type="image/svg+xml" data="https://images.emit.reviews/404-desktop.svg" aria-label="not-found" />

                <h1 className="not-found-heading">This page is not found...</h1>
                <p className="not-found-text">...and doesn’t want to be.</p>
                <a href={ROUTES.home.path} className="button-orange">Let’s go home</a>
            </Container>
        </Layout>
    );
};

NotFoundPage.propTypes = {
    pageContext: PropTypes.shape({
        schema: PropTypes.object.isRequired,
    }),
};
export default NotFoundPage
